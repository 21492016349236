// components/header/Header.js
import React, { useState, useContext } from 'react';
import {useNavigate} from "react-router-dom";
import {Link} from 'react-router-dom';
import {UserContext} from "../../context/UserContext";
import axiosApi from "../../lib/api";
import NavItem from './NavItem';
import Toast from "../UI/Toast"; // Import NavItem component

function Header({children}) {
    const {user, setUser} = useContext(UserContext);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            console.log('Handling Logout');
            const response = await axiosApi.post('user/logout/');
            if (response.status === 204) {
                console.log('Logout successful');
                setUser(null);
                navigate('/');
            }
        } catch (error) {
            console.error('Error during logout:', error);
            throw new Error('Logout failed');
        }
    };

    const menuItems = user
    ? [
          { to: '/products', label: 'Products' },
          { to: '/abtests', label: 'A/B Test' },
          {
              label: 'Log Out',
              onClick: handleLogout,
              isButton: true,
          },
      ]
    : [
          { to: '/login', label: 'Log In' },
          { to: '/faqs', label: 'FAQs' },
          { to: '/support', label: 'Support' },
          // { to: '/pricing', label: 'Pricing' },
      ];

    return (
        <div className="bg-gray-100">
            <nav className="bg-gray-800 p-4">
                <div className="container mx-auto flex justify-between items-center py-4 px-6">
                    {/* Logo */}
                    <Link to="/" className="text-white text-xl font-semibold">
                        <img
                            src="https://res.cloudinary.com/dtqxwjmwn/image/upload/v1731120992/bestsr.ai/bestsrai-white.png"
                            alt="BestSRAI Logo"
                            className="h-16 w-auto"
                        />
                    </Link>

                    {user && <div className="text-white text-md">Hello, {user.user}</div>}

                    {/* Hamburger Menu (Visible on Mobile) */}
                    <button
                        className="text-gray-300 hover:text-white focus:outline-none lg:hidden"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </button>

                    {/* Desktop Menu */}
                    <ul className="hidden lg:flex space-x-4 items-center">
                        {menuItems.map((item, index) => (
                            <li key={index}>
                                {item.isButton ? (
                                    <div
                                        onClick={item.onClick}
                                        className="cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    >
                                        {item.label}
                                    </div>
                                ) : (
                                    <NavItem to={item.to}>{item.label}</NavItem>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Mobile Menu */}
                {isMobileMenuOpen && (
                    <div className="lg:hidden bg-gray-800">
                        <ul className="flex flex-col space-y-4 p-4">
                            {menuItems.map((item, index) => (
                                <li key={index}>
                                    {item.isButton ? (
                                        <div
                                            onClick={item.onClick}
                                            className="cursor-pointer text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                        >
                                            {item.label}
                                        </div>
                                    ) : (
                                        <NavItem to={item.to}>{item.label}</NavItem>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </nav>
            <div className="min-h-screen">
                {children}
            </div>

            <Toast/>
        </div>
    );
}

export default Header;