import React, { useState, useContext } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";
import axiosApi from "../../lib/api";
import { UserContext } from '../../context/UserContext';
import {useNavigate} from "react-router-dom";
import { notify } from '../../components/UI/Toast';

const Login = () => {
   const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useContext(UserContext);

  // Yup validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  });

  // Initial values for the form fields
  const initialValues = {
    email: '',
    password: '',
  };

  // Form submission handler
  const handleSubmit = async(values) => {
    console.log('Form data:', values);

    const { email, password } = values;
    try {
      const response = await axiosApi.post('user/login/', { email, password });
      if (response.status === 200) {
        console.log('Login successful');
        setUser(response.data); // Handle user data if needed
        navigate('/products');
        notify.success(`Welcome, ${email}`)
      }
    } catch (error) {
      console.error('Error during login:', error);
      throw new Error('Login failed');
    }
    // Add your login logic here
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md mx-4 sm:mx-auto">
        <div className="text-center mb-6">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-gray-800 mb-2">
            Welcome to Amazon Seller
          </h2>
          <p className="text-sm sm:text-lg text-gray-600">Sign in to continue</p>
        </div>

        {/* Login Form */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form className="space-y-6">
              {/* Email Field */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Email
                </label>
                <Field
                  type="email"
                  name="email"
                  id="email"
                  className={`w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent ${
                    errors.email && touched.email
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Password Field */}
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 mb-2"
                >
                  Password
                </label>
                <div className="relative">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    className={`w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent ${
                      errors.password && touched.password
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="focus:outline-none text-gray-600 hover:text-gray-800 transition duration-150"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full py-3 bg-blue-600 text-white rounded-md font-semibold shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-200"
              >
                Sign In
              </button>
            </Form>
          )}
        </Formik>

        {/* Footer Text */}
        <div className="mt-6 text-center">
          <p className="text-sm sm:text-base text-gray-600">
            Please contact us for creating an account.
          </p>
        </div>
      </div>
    </div>

  );
};

export default Login;
