import React, {useContext, useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Header from './components/header/Header';
import Products from './pages/products/Products';
import Product from "./pages/product/Product";
import AbTests from "./pages/abtests/AbTests";
import AbTest from "./pages/abtest/AbTest";
import PageNotFound from "./pages/404/PageNotFound";
import CreateAbTest from "./pages/createabtest/CreateAbTest";
import LandingPage from "./pages/LandingPage";
import Login from "./pages/login/Login";
import FAQs from './pages/faqs/FAQs'
import Support from "./pages/support/Support";
import {UserContext, UserProvider} from "./context/UserContext";
import Pricing from 'pages/pricing/Pricing';

function App() {
    const {user, loading} = useContext(UserContext);

    if (loading) {
        return (
            <div
                className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-blue-100 via-white to-purple-100">
                <div className="flex items-center space-x-4">
                    <div
                        className="animate-spin rounded-full h-20 w-20 border-t-4 border-blue-500 border-opacity-70"></div>
                    <h2 className="text-3xl font-semibold text-gray-800">Loading...</h2>
                </div>

                <p className="text-lg text-gray-600 mt-4">Please wait while we prepare everything for you.</p>
            </div>
        );
    }
    return (

        <Router>
            <Header>
                {!user ? (
                    <Routes>
                        <Route path="/" element={<LandingPage/>}/>
                        <Route path="/login" element={<Login/>}/>
                        <Route path="/faqs" element={<FAQs/>}/>
                        <Route path="/support" element={<Support/>}/>
                        <Route path="/pricing" element={<Pricing />} />
                        {/*<Route path="*" element={<PageNotFound/>}/>*/}
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </Routes>
                ) : (
                    <div className="container mx-auto p-4 min-h-screen">
                        <Routes>
                            <Route path="/products" element={<Products/>}/>
                            <Route path="/products/:productId" element={<Product/>}/>
                            <Route path="/abtests" element={<AbTests/>}/>
                            <Route path="/abtests/:abTestId" element={<AbTest/>}/>
                            <Route path="/abtests/create" element={<CreateAbTest/>}/>
                            <Route exact path="/" element={<Navigate to="/products"/>}/>
                            <Route path="*" element={<PageNotFound/>}/>
                        </Routes>
                    </div>
                )}
            </Header>
        </Router>
    );
}

const AppWithContext = () => {
    return (
        <UserProvider>
            <App/>
        </UserProvider>
    )
}

export default AppWithContext;
