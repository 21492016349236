import { FaCheckCircle } from 'react-icons/fa';

function Pricing() {
  return (
    <div className="bg-gray-100 h-full flex flex-col items-center justify-center py-12">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Our Pricing Plans</h1>
          <p className="text-gray-600 mt-2">
            Affordable and flexible plans to fit every stage of your business journey.
          </p>
        </div>

        <div className="border border-gray-200 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold text-gray-900">Standard Package</h2>
          <p className="text-gray-600 text-lg mt-4 mb-8">
            $99 <span className="text-sm">/ month</span>
          </p>

          <ul className="space-y-4 mb-8">
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-3" />
              <span>1x Seller Account</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-3" />
              <span>Access to AB Testing</span>
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="text-green-500 mr-3" />
              <span>Access to LLM generated product attribute recommendation</span>
            </li>
          </ul>

          <a
            href="mailto:support@bestsr.ai"
            className="block text-center bg-blue-600 text-white font-bold py-2.5 rounded-full shadow hover:bg-blue-700 transition duration-300"
          >
            Request Demo & Create Account
          </a>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
