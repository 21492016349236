import React, {useState} from 'react';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import {FaTrash} from 'react-icons/fa';
import {axiosPrivate} from "../../lib/api";

import { notify } from '../../components/UI/Toast';
import {FiPlusCircle} from "react-icons/fi";

const DataTable = ({data, productId, setKeywordsData}) => {
    const {dates, ranks} = data;
    console.log(`Dates: ${dates}`)
    console.log(`Ranks: ${ranks}`)

    // Pagination state
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 7;

    // Calculate the data for the current page
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentDates = dates.slice(startIndex, endIndex);

    // Handle page navigation
    const handlePrev = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (endIndex < dates.length) setCurrentPage(currentPage + 1);
    };

    const handleArchiveButton = async(keywordId) => {
        try {
            const response = await axiosPrivate.post(`products/api/products/${productId}`, {
                step: "ARCHIVE_PRODUCT_KEYWORD",
                product_id: productId,
                keywordId: keywordId,
            });
            setKeywordsData(response.data.keyword_data);
            notify.info(response.data.message)
        } catch (error) {
            console.error('Error adding keyword:', error);
        }
    }
    if (ranks.length == 0){
        return (
            <div className="flex flex-col items-center justify-center p-4 m-4 ">
                <FiPlusCircle className="text-2xl text-green-500 mb-2"/> {/* Icon with styling */}
                <p className="text-gray-800 text-lg">You are not tracking any keywords. Start by creating one.</p>
            </div>
        )
    }
    return (
        <div>
            <table className="w-full border-collapse border border-gray-200 shadow-lg">
                <thead>
                <tr className="bg-gray-100">
                    <th className="px-4 py-2 border border-gray-200 text-left">Keyword</th>
                    {currentDates.map((date, index) => (
                        <th
                            key={index}
                            className="px-4 py-2 border border-gray-200 text-center"
                        >
                            {date}
                        </th>
                    ))}
                    <th className="px-4 py-2 border border-gray-200 text-left">Action</th>
                </tr>
                </thead>
                <tbody>
                {ranks.map((rank, rowIndex) => (
                    <tr
                        key={rowIndex}
                        className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                    >
                        <td className="px-4 py-2 border border-gray-200 font-bold capitalize">
                            {rank.keyword}
                        </td>

                        {currentDates.map((_, colIndex) => (
                            <td
                                key={colIndex}
                                className="px-4 py-2 border border-gray-200 text-center"
                            >
                                {rank.ranks[startIndex + colIndex] !== null
                                    ? rank.ranks[startIndex + colIndex]
                                    : '-'}
                            </td>
                        ))}
                        <td className="px-4 py-2 border border-gray-200 font-bold capitalize">
                            <button
                                onClick={() => handleArchiveButton(rank.id)}
                                className="text-red-400 hover:text-red-500 ml-2"
                            >
                                <FaTrash className="h-5 w-5"/>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="mt-4 text-center flex justify-between">
                <button
                    onClick={handlePrev}
                    disabled={currentPage === 0}
                    className={`inline-flex items-center px-4 py-2 mr-2 rounded-md text-white ${
                        currentPage === 0
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                >
                    <FaChevronLeft className="mr-2"/>
                    Previous
                </button>
                <button
                    onClick={handleNext}
                    disabled={endIndex >= dates.length}
                    className={`inline-flex items-center px-4 py-2 rounded-md text-white ${
                        endIndex >= dates.length
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-blue-500 hover:bg-blue-600'
                    }`}
                >
                    Next
                    <FaChevronRight className="ml-2"/>
                </button>
            </div>
        </div>
    );
};

export default DataTable;
